<template>
  <Toast />
  <ConfirmDialog />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">REPORTES</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">REPORTE DE STOCK</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>REPORTE GENERAL DE STOCK</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSelected"
            :options="sucursales"
            optionLabel="nombre"
            optionValue="id"
          >
          </Dropdown>
        </div>
        <div class="field col-12 md:col-6">
          <span><strong>NOMBRE/DESCRIPCI&Oacute;N:</strong></span>
          <div class="p-inputgroup">
            <InputText v-model="descripcion" @keyup.enter="BuscarFiltro()" />
            <Button
              icon="pi pi-search"
              class="p-button-warning"
              @click="BuscarFiltro()"
            />
          </div>
        </div>
        <!-- <div class="field col-12 md:col-6">
          <span><strong>NOMBRE PRODUCTO:</strong></span>
          <AutoComplete
            :dropdown="true"
            field="descripcion"
            v-model="productoSelected"
            :suggestions="productosListadoFiltrado"
            @complete="buscarProducto($event)"
            placeholder="Escriba Nombre Producto"
          >
            <template #item="slotProps">
              <div>
                <strong>{{ slotProps.item.descripcion }}</strong
                ><span><strong> ** </strong></span>
                {{ slotProps.item.fabrica_nombre }}
                <span><strong> ** </strong></span>
                <span
                  :class="
                    stockClass(
                      slotProps.item.stock.length > 0
                        ? sinDecimal(slotProps.item.stock[0].cantidad)
                        : 0
                    )
                  "
                  ><strong>{{
                    slotProps.item.stock.length > 0
                      ? sinDecimal(slotProps.item.stock[0].cantidad)
                      : 0
                  }}</strong></span
                >
              </div>
            </template>
          </AutoComplete>
        </div> -->
        <div class="field col-12 md:col-3">
          <span><strong>MARCA: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre"
            v-model="laboratorioSelected"
            :suggestions="laboratoriosListadoFiltrado"
            @complete="buscarLaboratorio($event)"
            placeholder="Buscar Marca"
          >
            <template #item="slotProps">
              <div>
                {{ slotProps.item.nombre }}
                <!-- **
                  {{ slotProps.item.nombre_proveedor }} -->
              </div>
            </template>
          </AutoComplete>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>PROVEEDOR: </strong></span>
          <AutoComplete
            :dropdown="true"
            field="nombre_completo"
            v-model="proveedorSelected"
            :suggestions="proveedoresListadoFiltrado"
            @complete="buscarProveedor($event)"
            placeholder="Buscar Proveedor"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>STOCK M&Iacute;N.: </strong></span>
          <InputNumber
            inputClass="text-right"
            v-model="precio_minimo"
            :useGrouping="false"
            :min="0"
            :showButtons="true"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>STOCK M&Aacute;XIMO: </strong></span>
          <InputNumber
            inputClass="text-right"
            :useGrouping="false"
            v-model="precio_maximo"
            :showButtons="true"
            :min="0"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>PRODUCTOS:</strong></span>
          <span class="p-inputgroup-addon">
            <span><strong>SIN STOCK:</strong></span>
            <Checkbox
              class="ml-2"
              v-model="stock_cero"
              value="sin_stock"
              v-tooltip.top="'Productos sin Stock'"
            />
          </span>
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-info mr-2"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>
    </div>
    <div
      class="card estilos-card mt-2"
      v-if="stocks.length > 0 && 'Producto Actualizar' in auth.user.permissions"
    >
      <div class="p-fluid formgrid grid align-items-center">
        <div class="field col-12 md:col-12 mt-2">
          <div
            class="p-inputgroup align-items-center"
            style="background-color: #f8d7da; padding: 2px; border-radius: 3px"
          >
            <i class="pi pi-info-circle p-mr-2" style="font-size: 1rem"></i>
            <span class="mr-1"><strong>NOTA: </strong></span>
            <small style="font-weight: bold; color: #ff0000">
              Si incrementa el precio en un % (por ejemplo, 10%) y luego desea
              reducirlo, se recomienda hacerlo con el mismo % (por ejemplo,
              -10%) para mantener la coherencia en los cálculos.
            </small>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup align-items-center">
            <Checkbox
              v-model="updatePrice"
              :binary="true"
              @change="porcentaje_update = updatePrice ? 0 : null"
              :disabled="stocks.length == 0"
            />
            <span class="ml-1"
              ><strong>ACTUALIZAR PRECIOS DE VENTA</strong></span
            >
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <div class="p-inputgroup align-items-center">
            <Checkbox
              v-model="redondear_precio"
              :binary="true"
              :disabled="!updatePrice"
            />
            <span class="ml-1"><strong>REDONDEAR PRECIO</strong></span>
          </div>
        </div>
        <div class="field col-12 md:col-3">
          <InputNumber
            v-model="porcentaje_update"
            :showButtons="true"
            :min="-100"
            :max="100"
            :suffix="'%'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            :disabled="!updatePrice"
            class="p-inputtext-sm"
          />
        </div>
        <div class="field col-12 md:col-3">
          <Button
            icon="pi pi-refresh"
            label="ACTUALIZAR PRECIOS"
            class="p-button-success p-button-sm"
            style="font-weight: bold"
            :loading="actualizando_precios"
            :disabled="
              actualizando_precios || !updatePrice || !porcentaje_update
            "
            @click="updatePrice ? updatePrecioVenta() : null"
          />
        </div>
      </div>
    </div>
    <div
      class="mt-2 card style_card"
      v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
    >
      <div class="p-fluid formgrid grid">
        <table class="table table-bordered table-striped" style="width: 100%">
          <tr>
            <th class="text-center">
              TOTAL INVERSI&Oacute;N BRUTA:
              <span
                class="p-badge p-badge-info p-mr-2"
                v-tooltip.top="'Total Inversión Bruta (Ultimo CPP)'"
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(sumaTotalInversion ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              TOTAL VALOR VENTA:
              <span
                class="p-badge p-badge-warning p-mr-2"
                v-tooltip.top="'Total Valor Venta (Ultimo Precio Venta)'"
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(sumaTotalValorVenta ?? 0)
                }}
                Bs.</span
              >
            </th>
            <th class="text-center">
              TOTAL UTILIDAD:
              <span
                class="p-badge p-badge-success p-mr-2"
                v-tooltip.top="
                  'Total Utilidad (Total Valor Venta - Total Inversión Bruta)'
                "
                style="font-size: 1rem"
                >{{
                  convertirNumeroGermanicFormat(sumaTotalUtilidad ?? 0)
                }}
                Bs.</span
              >
            </th>
          </tr>
        </table>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="reporte_stock"
        key="id"
        :value="stocks"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Stock"
        selectionMode="single click"
        style="font-size: 11px; font-family: 'Arial'"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Stock..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="PDF Stock"
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger"
                v-tooltip.top="'Exportar PDF Stock en Almacen'"
                :loading="generando_pdf"
                :disabled="generando_pdf"
                @click="generarPDF()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
                label="EXCEL Exportar"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success mr-2"
                v-tooltip.top="'Exportar Stock en Almacen'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStock()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                v-if="'R_Stock DescargarEXCEL' in auth.user.permissions"
                label="EXCEL Control de Stock"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Exportar Control de Stock'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportStockControl()"
              />
            </span>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span
            class="flex align-items-center justify-content-center"
            style="
              background-color: #f8d7da;
              padding: 5px;
              border-radius: 5px;
              color: #721c24;
            "
          >
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <!--  <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column> -->
        <Column field="cod_producto" header="CÓD"></Column>
        <Column field="sucursal" header="SUCURSAL" v-if="sucursales.length > 2">
          {{ sucursal }}
        </Column>
        <Column field="laboratorio" header="MARCA" :sortable="true" />
        <Column field="proveedor" header="PROVEEDOR" :sortable="true" />

        <Column
          field="producto"
          header="DESCRIPCIÓN/PRODUCTO"
          style="font-weight: bold"
        >
          <template #body="{ data }">
            <div>
              {{ data.producto }}
            </div>
          </template>
        </Column>
        <Column
          class="text-center"
          field="cantidad"
          header="STOCK"
          :sortable="true"
        >
          <template #body="{ data }">
            <div :class="claseEstado(data)">
              {{ sinDecimal(data.cantidad) }}
            </div>
          </template>
        </Column>
        <Column class="text-right" field="precio_compra" header="P.COMPRA">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_compra) }}
            </div>
          </template>
        </Column>
        <Column class="text-right" field="precio_sugerido" header="P.VENTA">
          <template #body="{ data }">
            <div>
              {{ convertirNumeroGermanicFormat(data.precio_sugerido) }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="precio_compra"
          header="T.COMPRA"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_compra * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="precio_sugerido"
          header="T.VENTA"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_sugerido * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
        <Column
          v-if="'R_Ganancias de Ganancias' in auth.user.permissions"
          class="text-right"
          field="utilidad"
          header="UTILIDAD"
        >
          <template #body="{ data }">
            <div>
              {{
                convertirNumeroGermanicFormat(
                  data.precio_sugerido * data.cantidad -
                    data.precio_compra * data.cantidad
                )
              }}
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import StockService from "@/service/StockService";
import SucursalService from "@/service/SucursalService";
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";
import { sum } from "lodash";

export default {
  data() {
    return {
      stocks: [],
      sucursalSelected: 0,
      sucursales: [{ id: 0, nombre: "TODAS" }],
      enviando: false,
      stock_cero: false,
      exportando: false,
      generando_pdf: false,
      productoSelected: null,
      productosListadoFiltrado: [],
      precio_minimo: 0,
      precio_maximo: 0,
      buscar: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      laboratorioSelected: null,
      laboratoriosListadoFiltrado: [],
      proveedorSelected: null,
      proveedoresListadoFiltrado: [],
      numero_elementos: 0,
      stockClass: (data) => {
        if (data <= 0) {
          return "outofstock";
        } else if (data <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      claseEstado: (data) => {
        if (data.cantidad <= 0) {
          return "outofstock";
        } else if (data.cantidad <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
      updatePrice: false,
      redondear_precio: false,
      porcentaje_update: null,
      actualizando_precios: false,
      descripcion: null,
    };
  },
  stockService: null,
  sucursalService: null,
  productService: null,
  auth: null,
  created() {
    this.stockService = new StockService();
    this.sucursalService = new SucursalService();
    this.initBuscar();
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
  },
  computed: {
    sumaTotalInversion() {
      return sum(
        this.stocks.map((item) => {
          return item.cantidad * item.precio_compra;
        })
      );
    },
    sumaTotalValorVenta() {
      return sum(
        this.stocks.map((item) => {
          return item.precio_sugerido * item.cantidad;
        })
      );
    },
    sumaTotalUtilidad() {
      /* sumo el valor de this.sumaTotalValorVenta - this.sumaTotalInversion */
      return this.sumaTotalValorVenta - this.sumaTotalInversion;
    },
  },
  methods: {
    updatePrecioVenta() {
      this.actualizando_precios = true;
      let datos = {
        sucursal_id: this.sucursalSelected,
        stocks: this.stocks,
        updatePrice: this.updatePrice ? true : false,
        redondear_precio: this.redondear_precio ? true : false,
        porcentaje: this.porcentaje_update,
      };
      this.$confirm.require({
        header: "Confirmación",
        message: "¿ESTÁ SEGURO DE ACTUALIZAR LOS PRECIOS DE VENTA?",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "SI, ACTUALIZAR",
        rejectLabel: "NO, CANCELAR",
        acceptClass: "p-button-primary p-button-lg",
        rejectClass: "p-button-danger p-button-lg",
        acceptIcon: "pi pi-save",
        rejectIcon: "pi pi-times",
        discard: () => {
          this.actualizando_precios = false;
        },
        accept: () => {
          this.stockService
            .updatePrecioVenta(datos)
            .then((data) => {
              if (data.status == 400) {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: data.message,
                  life: 6000,
                });
                this.actualizando_precios = false;
              } else {
                this.actualizando_precios = false;
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito",
                  detail: data.message,
                  life: 3000,
                });
                this.BuscarFiltro();
              }
            })
            .catch(() => {
              this.actualizando_precios = false;
            });
        },
        reject: () => {
          this.$toast.add({
            severity: "info",
            summary: "Información",
            detail: "Se canceló la operación",
            life: 3000,
          });
          this.enviando = false;
        },
      });
    },
    incrementar_numero_elementos() {
      this.numero_elementos++;
      return this.numero_elementos;
    },
    buscarLaboratorio(event) {
      setTimeout(() => {
        this.stockService
          .buscarLaboratorioXNombre({ texto: event.query })
          .then((response) => {
            this.laboratoriosListadoFiltrado = [...response.laboratorios];
          });
      }, 250);
    },
    buscarProveedor(event) {
      setTimeout(() => {
        this.stockService
          .buscarProveedorXNombre({ texto: event.query })
          .then((response) => {
            this.proveedoresListadoFiltrado = [...response.proveedores];
          });
      }, 250);
    },
    /* buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({
            texto: event.query,
            sucursal_id: this.sucursalSelected,
          })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    }, */
    buscarProducto() {
      let datos = {
        sucursal_id: this.sucursalSelected,
        texto: this.descripcion,
      };
      this.productService.buscarXNombre(datos).then((data) => {
        this.productosListadoFiltrado = [...data.productos];
      });
    },
    generarPDF() {
      if (this.stocks.length > 0) {
        this.generando_pdf = true;
        let datos = {
          sucursal: this.sucursalSelected,
          stock_cero: this.stock_cero.length > 0 ? true : false,
          producto: this.descripcion,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService
          .imprimirReporte(datos)
          .then(() => {
            this.generando_pdf = false;
            this.$toast.add({
              severity: "success",
              summary: "Éxito",
              detail: "Se generó el reporte correctamente",
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.generando_pdf = false;
          });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.stocks = [];
      this.enviando = true;
      this.cargarStocksSucursal();
    },
    cargarStocksSucursal() {
      let datos = {
        sucursal: this.sucursalSelected,
        producto: this.descripcion,
        precio_minimo: this.precio_minimo,
        precio_maximo: this.precio_maximo,
        stock_cero: this.stock_cero.length > 0 ? true : false,
        proveedor_id: this.proveedorSelected ? this.proveedorSelected.id : null,
        fabrica_id: this.laboratorioSelected
          ? this.laboratorioSelected.id
          : null,
      };
      this.stockService
        .filtrarStockSucursal(datos)
        .then((data) => {
          this.stocks = data.stocks;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((element) => {
            this.sucursales.push(element);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportStockControl() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          producto: this.descripcion,
          stock_cero: this.stock_cero,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService.exportStockAllControl(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    exportStock() {
      if (this.stocks.length > 0) {
        let datos = {
          sucursal: this.sucursalSelected,
          producto: this.descripcion,
          stock_cero: this.stock_cero,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
          proveedor_id: this.proveedorSelected
            ? this.proveedorSelected.id
            : null,
          fabrica_id: this.laboratorioSelected
            ? this.laboratorioSelected.id
            : null,
        };
        this.stockService.exportStockAll(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se exportó el reporte correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para exportar",
          life: 3000,
        });
      }
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 10px;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
.style_card {
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
  padding: 3px;
}
.p-inputgroup-addon {
  color: white;
  background-color: #ff2f2f;
  font-weight: bold;
  border: none;
}
.estilos-card {
  border-radius: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0em;
  border: 1px solid #000000;
  border-bottom: 3px solid #000000;
}
</style>
