import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class StockService {
  async getStockAll() {
    return await fetchWrapper.get(`${ruta}/stock/reportes/todos`);
  }

  async productos_stock_cero(){
    return await fetchWrapper.get(`${ruta}/productos_stock_cero`);
  }

  async exportStockAll(datos){
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/stockall-export`, datos
    );
    return exportado;
  }
  async exportStockAllControl(datos){
    const exportado = await fetchWrapper.postWithBlob(
      `${ruta}/stockall-control-export`, datos
    );
    return exportado;
  }
  async filtrarStockSucursal(datos) {
    return await fetchWrapper.post(`${ruta}/filtros_stock`,datos);
  }

  async imprimirReporte(datos){
    return await fetchWrapper.postPdf(`${ruta}/reporte/stock/imprimir`, datos);
  }

  async buscarLaboratorioXNombre(datos) {
    return await fetchWrapper.post(`${ruta}/laboratorio/buscar`, datos);
  }

  async buscarProveedorXNombre(datos) {
    return await fetchWrapper.post(`${ruta}/proveedor/buscar`, datos);
  }
  async updatePrecioVenta(datos) {
    return await fetchWrapper.post(
      `${ruta}/stock/actualizar_preciosventas`,
      datos
    );
  }
}